import React from "react";
import Footer from "@/components/footer-interna";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import BertTop   from '@/components/bert-top'
import BertSwiper from '@/components/bert-swiper';
import Projetos from '@/components/projetos';
import Map from '@/components/map';
import NossasTurmasBert from '@/components/NossasTurmasBert';
import FundamentalAnosIniciaisBert from '@/components/FundamentalAnosIniciaisBert';

const BertPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Bert International Schools">
          <HeaderOne />
          <BertTop />
          <BertSwiper />
          <NossasTurmasBert />
          <FundamentalAnosIniciaisBert/>
          <Projetos />
          <Map />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default BertPage;