/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import { Link } from "gatsby"
import ProductsCarousel from "@/components/carousel-store";
import ButtonLink from "@/components/button"

import { 
  oDiferencial,
}  from '../assets/styles/LojaVirtual.styles'

const LojaVirtual = () => {
  return (
    <Fragment>
      <section css={oDiferencial}>
        <Container fluid className="wrapper">
          <Row>
            <Col className="content" lg={5} md={5} sm={12} data-aos-delay={"1000"} data-aos="fade-right">
             <h2><span>Be Bert!<br/> Be Global!</span></h2>
              <p>Os Berts usam peças únicas que unem conforto e design. Visite a loja virtual e conheça nossa coleção!</p>
              <div className="bt-loja">
                  <ButtonLink 
                    color="secondary"
                    text="Conheça os uniformes" 
                    href="https://loja.bertinternational.com.br/"
                  />
              </div>
            </Col>
            <Col lg={7} md={6} sm={12} data-aos-delay={"1000"} data-aos="fade-left">
              <ProductsCarousel />
            </Col>
            <div className="bt-loja-mobile">
                  <ButtonLink 
                    color="secondary"
                    text="Conheça os uniformes" 
                    href="https://loja.bertinternational.com.br/"
                  />
              </div>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default LojaVirtual;
