/** @jsx jsx */
import { css } from '@emotion/react'

export const lideresHeader = css`
  padding: 88px 0 60px;
  overflow: hidden;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #C2F57E;
  h2 {
    color: #33535E;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom:32px;
    span{
        color: #3B43FF;
    }
  }
  h4{
    font-family:"obviously-narrow";
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    line-height: 150%;
    color: #33535E;
    text-transform: uppercase;
    letter-spacing: 0;
    margin-bottom: 32px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #33535E;
    margin-bottom: 16px;
    /* height: 100%;
    align-items: center;
    display: flex; */
  }
  .btn-agende {
    display: flex;
    justify-content: left;
    margin: 50px 0 70px 0;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 54px 0;
    .imgCurriculo{
      display: none;
    }
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
  }
`

export const bertBert = css`
  padding: 60px 0;
  overflow: hidden;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #C2F57E;
  h1 {
    color: #33535E;
    font-size: 244px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    z-index: 1;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #312F2D;
    margin-bottom: 0px;
    width: 100%;
    max-width: 350px;
    margin-left: auto;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  img {
    margin-left: -100px;
    margin-top: 32px;
  }
  .btn-agende {
    display: flex;
    justify-content: left;
    margin-left: 95px;
  }
  a {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border: 2px solid #33535E;
    width: 220px;
    height: 52px;
    padding: 14px 24px 14px 32px;
    display: flex;
    justify-content: center;
    color: #33535E;
    transition: transform 2000ms ease,opacity 2000ms ease,color 500ms ease,background 500ms ease,-webkit-transform 2000ms ease;
    align-items: center;
    svg {
      margin-left: 8px;
    }
    :hover {
      background: #3B43FF;
      border: #3B43FF;
      color: #EFEFEF;
      text-decoration: none;
    }
    :active {
      border: 2px solid #312F2D;
      color: #312F2D;
      background: #EFEFEF;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 0px;
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      text-align: center;
      margin: auto;
      margin-top: 42px;
      margin-bottom: 32px;
    }
    .btn-agende {
      justify-content: center;
      margin-left: 0px;
    }
    .bertMobile {
      text-align: center;
      display: flex;
      flex-direction: column;
      h1 {
        margin-left: -160px;
      }
      h2 {
        color: #33535E;
        font-size: 244px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 96px */
        text-transform: uppercase;
        z-index: 1;
        margin-bottom: 0px;
        margin-top: -115px;
      }
      img {
        margin-left: 0px;
        margin-top: -70px;
      }
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-top: 0px;
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      text-align: center;
      margin: auto;
      margin-top: 42px;
      margin-bottom: 32px;
    }
    .btn-agende {
      justify-content: center;
      margin-left: 0px;
    }
    .bertMobile {
      text-align: center;
      display: flex;
      flex-direction: column;
      h1 {
        margin-left: -160px;
      }
      h2 {
        color: #33535E;
        font-size: 244px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 96px */
        text-transform: uppercase;
        z-index: 1;
        margin-bottom: 0px;
        margin-top: -115px;
      }
      img {
        margin-left: 0px;
        margin-top: -70px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-top: 0px;
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      text-align: center;
      margin: auto;
      margin-top: 42px;
      margin-bottom: 32px;
    }
    .btn-agende {
      justify-content: center;
      margin-left: 0px;
    }
    .bertMobile {
      text-align: center;
      display: flex;
      flex-direction: column;
      h1 {
        margin-left: -160px;
      }
      h2 {
        color: #33535E;
        font-size: 244px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 96px */
        text-transform: uppercase;
        z-index: 1;
        margin-bottom: 0px;
        margin-top: -115px;
      }
      img {
        margin-left: 0px;
        margin-top: -70px;
      }
    }
  }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`