/** @jsx jsx */
import { css } from "@emotion/react";

export const mainSliderTwo = css`

  margin-top: 86px;
  p,
  h3 {
    color: #000000;
  }

  .image-layer{
    background-color: #3C5960;
  }

  .mobile{
    display: none;
  } 
  @media (min-width: 320px) and (max-width: 767px) {
    .mobile{
      display: block;
    }
    .desktop{
      display: none;
    }
  }

`;

export const sliderContent = css`
  h3 {
    font-family: "obviously-narrow";
    font-variation-settings: "wght" 700;
    color: #EFEFEF;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; 
    text-transform: uppercase;
    width: 100%;
    max-width: 445px;
    margin-bottom: 16px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    color: #EFEFEF;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: initial;
    letter-spacing: 1px;
    margin-bottom: 32px;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column-reverse;

    h3 {
      font-size: 42px;
    }
    .text-left{
      background-color: #3B43FF;
      padding: 54px 16px 64px;
      h3{
        font-size: 48px;
        line-height: 120%;
      }

    }
    .text-right{
      overflow: clip;
      padding: 0;
      .desktop{
        display: none;
      }
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h3 {
      font-size: 42px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h3 {
      font-size: 42px;
    }
  }
`
