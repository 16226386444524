/** @jsx jsx */
import React, { useState } from "react";
import { jsx } from "@emotion/react";
import {
  menuContainer,
  menuTitle,
  menuItem,
  contentContainer,
  contentTitle,
  listItem,
  sectionContainer,
  DDesktop,
  DMobile,
  modalTitle,
  modalContent,
  modal,
  contentModalTitle,
  horariosModal
} from "../assets/styles/LIstademateriais.styles";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import calendarIcon from "../assets/images/lp/icons/calendar-icon.svg";
import bookIcon from "../assets/images/lp/icons/book-icon.svg";
import clockIcon from "../assets/images/lp/icons/clock-icon.svg";
import materialIcon from "../assets/images/lp/icons/material-icon.svg";
import fileIcon from "../assets/images/lp/icons/file-icon.svg";

const ListaDeMateriais = () => {
  const [activeContent, setActiveContent] = useState("material");
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (content) => {
    setActiveContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const renderModalContent = () => {
    switch (activeContent) {
      case "calendar":
        return (
          <>
            <div css={modalTitle}>CALENDÁRIO ESCOLAR 2025</div>
            <ul>
              <li css={listItem}>
                <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/CALEND%C3%81RIO%202025%20BERT%20INTERNATIONAL%20SCHOOL.jpeg" target="_blank">
                <img src={fileIcon} />
                <p>Ensino Fundamental e Médio 2025</p>
                </a>
              </li>
            </ul>
          </>
        );
      case "material":
        return (
          <>
            <div css={modalTitle}>LISTA DE MATERIAIS ESCOLARES</div>
            <ul>
              <li css={listItem}>
                <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/6%C2%BA%20ANO%202025.pdf" target="_blank">
                <img src={fileIcon} />
                <p>Lista de materiais 6º ANO 2025</p>
                </a>
              </li>
              <li css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/7%C2%BA%20ANO%202025.pdf" target="_blank">
                  <img src={fileIcon} />
                  <p>Lista de materiais 7º ANO 2025</p>
                </a>
              </li>
              <li css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/8%C2%BA%20ANO%202025.pdf" target="_blank">
                  <img src={fileIcon} />
                  <p>Lista de materiais 8º ANO 2025</p>
                </a>
              </li>
              <li css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/9%C2%BA%20ANO%202025.pdf" target="_blank">
                  <img src={fileIcon} />
                  <p>Lista de materiais 9º ANO 2025</p>
                </a>
              </li>
              <li css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/ENSINO%20M%C3%89DIO.pdf" target="_blank">
                  <img src={fileIcon} />
                  <p>Lista de materiais Ensino Médio 2025</p>
                </a>
              </li>
            </ul>
          </>
        );
      case "schedule":
        return (
          <>
            <div css={contentModalTitle}>HORÁRIOS</div>


            <div css={horariosModal}>
              <h3>BERT INTERNATIONAL SCHOOL</h3>
              <p>Horário da escola: </p>
              <p>7h15 às 12h35 - Integral 14h15 às 17h30</p>
              <p>Middle School e High School - Início 13h30</p>
            </div>
          </>
        );
      default:
        return <p>Bem-vindo ao menu!</p>;
    }
  };

  const renderContent = () => {
    switch (activeContent) {
      case "calendar":
        return (
          <>
          <div css={contentTitle}>CALENDÁRIO ESCOLAR 2025</div> 
            <div>
              <div css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/CALEND%C3%81RIO%202025%20BERT%20INTERNATIONAL%20SCHOOL.jpeg" target="_blank">
                  <img src={materialIcon} />
                  <p>Ensino Fundamental e Médio 2025</p>
                </a>
              </div>
            </div>
          </>
        );
      case "material":
        return (
          <>
            <div css={contentTitle}>LISTA DE MATERIAIS ESCOLARES</div>
            <div>
              <div css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/6%C2%BA%20ANO%202025.pdf" target="_blank">
                  <img src={materialIcon} />
                  <p>Lista de materiais 6º ANO 2025</p>
                </a>
              </div>
              <div css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/7%C2%BA%20ANO%202025.pdf" target="_blank">
                  <img src={materialIcon} />
                  <p>Lista de materiais 7º ANO 2025</p>
                </a>
              </div>
              <div css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/8%C2%BA%20ANO%202025.pdf" target="_blank">
                  <img src={materialIcon} />
                  <p>Lista de materiais 8º ANO 2025</p>
                </a>
              </div>
              <div css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/9%C2%BA%20ANO%202025.pdf" target="_blank">
                  <img src={materialIcon} />
                  <p>Lista de materiais 9º ANO 2025</p>
                </a>
              </div>
              <div css={listItem}>
              <a href="https://cdn.fractaltecnologia.com.br/documents/Bert%20International/ENSINO%20M%C3%89DIO.pdf" target="_blank">
                  <img src={materialIcon} />
                  <p>Lista de materiais Ensino Médio 2025</p>
                </a>
              </div>
            </div>
          </>
        );
      case "schedule":
        return (
          <>
            <div css={contentTitle}>HORÁRIOS</div>
            <h3>BERT INTERNATIONAL SCHOOL</h3>

            <div>
              <p>Horário da escola: </p>
              <p>7h15 às 12h35 - Integral 14h15 às 17h30</p>
              <p>Middle School e High School - Início 13h30</p>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <section>
      <Container css={sectionContainer} fluid>
        <Container css={DMobile}>
          <Col md={12} css={menuContainer}>
            <div css={menuTitle}>MENU</div>
            <div css={menuItem} onClick={() => handleOpenModal("calendar")}>
              <img src={calendarIcon} alt="Calendário" />
              <span>CALENDÁRIO</span>
              <i class="arrow"></i>
            </div>
            <div css={menuItem} onClick={() => handleOpenModal("material")}>
              <img src={bookIcon} alt="Material Escolar" />
              <span>MATERIAL ESCOLAR</span>
              <i class="arrow"></i>
            </div>
            <div css={menuItem} onClick={() => handleOpenModal("schedule")}>
              <img src={clockIcon} alt="Horários" />
              <span>HORÁRIOS</span>
              <i class="arrow"></i>
            </div>
          </Col>

          {/* Modal */}
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            dialogClassName="custom-modal"
            css={modal}
          >
            <Modal.Body css={modalContent}>
              <Button
                variant="secondary"
                onClick={handleCloseModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  borderRadius: "50%",
                }}
              >
                ✕
              </Button>
              {renderModalContent()}
            </Modal.Body>
          </Modal>
        </Container>
        <Container css={DDesktop}>
          <Row style={{ width: "100%" }}>
            {/* Menu Sidebar */}
            <Col lg={4} css={menuContainer}>
              <div css={menuTitle}>MENU</div>
              <div
                className={activeContent === "calendar" ? "active" : null}
                css={menuItem}
                onClick={() => setActiveContent("calendar")}
              >
                <img src={calendarIcon} alt="Calendário" />
                <span>CALENDÁRIO</span>
                <i class="arrow"></i>
              </div>
              <div
                css={menuItem}
                className={activeContent === "material" ? "active" : null}
                onClick={() => setActiveContent("material")}
              >
                <img src={bookIcon} alt="Material Escolar" />
                <span>MATERIAL ESCOLAR</span>
                <i class="arrow"></i>
              </div>
              <div
                css={menuItem}
                className={activeContent === "schedule" ? "active" : null}
                onClick={() => setActiveContent("schedule")}
              >
                <img src={clockIcon} alt="Horários" />
                <span>HORÁRIOS</span>
                <i class="arrow"></i>
              </div>
            </Col>

            {/* Main Content */}
            <Col lg={8} css={contentContainer}>
              {renderContent()}
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
};

export default ListaDeMateriais;
