/** @jsx jsx */
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import MapImg from "@/images/lp/map.png"
import MarkerIcon from "@/images/lp/icons/marker.svg"
import InstagramIcon from "@/images/lp/icons/instagram.svg"
import WhatsappIcon from "@/images/lp/icons/whatsapp.svg"

import { 
  contatosEVisitas,
  btnEnviar
}  from '../assets/styles/Map.styles'

const Contatos = () => {

  const [showAgendarVisita, setShowAgendarVisita] = useState(false)

  const handleCheckboxChange = (e) => {
    setShowAgendarVisita(e.target.checked)
    console.log('revelar')
  }

  return (
    <Fragment>
      <section css={contatosEVisitas} id="contatos">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} className="p-0">
              <div className="MB-120 contact-text">
                <div>
                  <h2>CENTRAL DE<br/> RELACIONAMENTO<br/> BERT INTERNATIONAL<br/> SCHOOL</h2>
                  <h4>Fundamental Anos Finais ao Ensino Médio</h4>
                </div>
                <div className="map-text">
                    <p><img src={MarkerIcon} /> R. Arquiteto Décio Luís Cardoso, 469 - Centro, Foz do Iguaçu - PR, 85853-530</p>
                    <p><img src={InstagramIcon} /> Siga: <b>instagram.com/bert.schools</b></p>
                    <p><img src={WhatsappIcon} /> Fale Conosco: <b><a href='https://wa.me/5545988276543' target="_blank">(45) 98827-6543</a></b></p>
                </div>
              </div>
            </Col><br/>
            <Col lg={6} md={6} sm={12} className="contact-form">

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3599.822163126089!2d-54.59117152540258!3d-25.544300037422516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f690356e478b79%3A0x3d9b71481269d0f7!2sR.%20Arquiteto%20D%C3%A9cio%20Lu%C3%ADs%20Cardoso%2C%20469%20-%20Centro%2C%20Foz%20do%20Igua%C3%A7u%20-%20PR%2C%2085851-445!5e0!3m2!1spt-BR!2sbr!4v1734715156639!5m2!1spt-BR!2sbr" width="475"
                height="374"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade">
            </iframe>
              
            </Col>
            <Col lg={1} md={1} sm={12} />
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default Contatos;
