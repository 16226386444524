/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { fundAnosIniciaisStyles } from '../assets/styles/FundAnosIniciaisBert.styles';
import { Col, Container, Row } from 'react-bootstrap';
import studentsLego from '../assets/images/lp/img-ensino-medio.jpg'

const FundamentalAnosIniciaisBert = () => {
  return (
    <section css={fundAnosIniciaisStyles}>
      <Container className='content' fluid>
        <Row className='row-reverse'>
          <Col>
            <h2>Ensino Médio</h2>
            <h3>da 1° a 3° série</h3>
            <Row className='row-list'>
              <Col>
                <ul>
                  <li>Dupla diplomação: Ensino Médio brasileiro + High School americano</li>
                  <li>Grupos de estudo</li>
                  <li>Avaliação Formativa</li>
                  <li>Programa socioemocional</li>
                  <li>Orientação pedagógica</li>
                  <li>Ensino de qualidade em em Linguagem, Matemática, Ciências</li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li>Sistema POLIEDRO</li>
                  <li>Pensamento Matemático</li>
                  <li>Aulas de Health and Science, World History and Life Skils</li>
                  <li>Metodologias CLIL (Content and Language integrated Learning)</li>
                  <li>Orientação pedagógica personalizada</li>
                </ul>
              </Col>
            </Row>
          </Col>
          <div className='col-img'>
            <img src={studentsLego} />
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default FundamentalAnosIniciaisBert;
