/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { atividadesAdicionais, DDesktop, DMobile } from '../assets/styles/BertKidsContent.styles.js';
import { Col, Container, Row } from 'react-bootstrap';
import bertTop from '../assets/images/lp/bert-top.jpg'
import bertTopMob from '../assets/images/lp/bert-mobile.jpg'

const BertTop = () => {
  return (
    <section>
      <div css={atividadesAdicionais}>
        <div className="text-right">
          <img style={{ marginLeft: 'auto' }} css={DDesktop} src={bertTop} alt='/' />
          <img css={DMobile} src={bertTopMob} alt='/' />
        </div>
        <Container>
          <Row style={{ alignItems: 'center' }}>
            <Col>
              <h2>Bert <br /> International Schools</h2>
              <h3>Conheça o melhor colégio internacional bilíngue de Foz do Iguaçu!</h3>
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '24px', marginTop: '40px' }}>
              <p>
              O caminho para o futuro começa na formação acadêmica. Nossa proposta educacional bilíngue forma cidadãos globais, preparados para se destacarem nas melhores universidades do país e do mundo.
              </p>
              <p>
                Além de atender às exigências curriculares da BNCC (Base Nacional Comum Curricular) do Brasil, aplicamos a metodologia CLIL (Content and Language Integrated Learning), com aulas de Biologia e História lecionadas em português e inglês, e a metodologia STEAM, com o ensino integrado de Ciência, Tecnologia, Engenharia, Artes e Matemática.
              </p>
              <p>
              Da performance acadêmica ao desenvolvimento do pensamento internacional, da aprovação em universidades à fluência em inglês. Preparamos nossos alunos para serem globais, inovadores e líderes criativos!
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default BertTop;
