/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import IcoArrow from "@/images/lp/icons/arrow-b.svg"
import IcoCiaTeatro from "@/images/lp/icons/cia-teatro.svg"
import IcoFabLab from "@/images/lp/icons/fab-lab.svg"
import IcoStrike from "@/images/lp/icons/strike.svg"
import ImgBert from "@/images/lp/img-bert.svg"

import { 
  diferenciais,
}  from '../assets/styles/Projetos.styles'
import { Link } from "gatsby"

const Projetos = () => {
  return (
    <Fragment>
      <section css={diferenciais} id="projetos">
        <Container>
          <Row style={{marginBottom: '72px'}}>
            <Col lg={6} md={6} sm={12}>
              <h2>Projetos</h2>
              <h4>atividades complementares que vão além do currículo tradicional:</h4>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <img src={ImgBert} />
            </Col>
          </Row>
          <Row>
          <Col className="project" lg={4} md={4} sm={12}>
              <h3>STEAM</h3>
              <p>Iniciação à equipe internacional de Robótica Industrial do BERT Programação, Engenharia, Design Thinking, Empreendedorismo e Marketing
              </p>
          </Col>
          <Col className="project" lg={4} md={4} sm={12}>
              <h3>TEATRO</h3>
              <p>Práticas para autoexpressão e desenvoltura Storytelling, escrita de roteiros, técnicas de memorização e direção de arte</p>
          </Col>
          <Col className="project" lg={4} md={4} sm={12}>
              <h3>EXTRACURRICULAR COURSES</h3>
              <p>Espanhol, Alemão, Esportes, Matemática, Árabe, Artes Visuais</p>
          </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default Projetos;
