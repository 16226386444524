import { css } from '@emotion/react'

export const excelenciaInternacional = css`
  padding: 0;
  overflow: hidden;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #33535E;
  h2 {
    position: absolute;
    color: #EFEFEF;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    text-transform: uppercase;
    margin-bottom: 0px;
    span {
      color: #C2F57E;
    }
  }
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: #EFEFEF;
    padding-top: 32px;
    span {
      color: #C2F57E;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #EFEFEF;
    margin-bottom: 0px;
  }
  img {
    width:100%;
  }
  .content-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .bt-loja{
    
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 32px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
   
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 32px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 32px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
  }
`

export const acaoGlobal = css`
  padding: 80px 0;
  overflow: hidden;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #33535E;
  h2 {
    color: #EFEFEF;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    text-transform: uppercase;
    margin-bottom: 8px;
    span {
      color: #C2F57E;
    }
  }
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: #EFEFEF;
    margin-bottom: 8px;
    span {
      color: #C2F57E;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #EFEFEF;
    margin-bottom: 0px;
  }
  .dp-end {
    display: flex;
    align-items: end;
  }
  .acoes-dados {
    display: flex;
    flex-direction: column;
    :not(:last-child) {
      margin-bottom: 40px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      text-align: center;
      margin-bottom: 56px;
    }
    img {
      width: 60% !important;
      margin-bottom: 32px;
    }
    .dp-end {
      display: flex;
      justify-content: center;
    }
    .acoes-dados { 
      text-align: center;
      h2, h3 {
        margin-bottom: 8px;
      }
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      text-align: center;
      margin-bottom: 56px;
    }
    img {
      width: 60% !important;
      margin-bottom: 32px;
    }
    .dp-end {
      display: flex;
      justify-content: center;
    }
    .acoes-dados { 
      text-align: center;
      h2, h3 {
        margin-bottom: 8px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      text-align: center;
      margin-bottom: 56px;
    }
    img {
      width: 60% !important;
      margin-bottom: 32px;
    }
    .dp-end {
      display: flex;
      justify-content: center;
    }
    .acoes-dados { 
      text-align: center;
      h2, h3 {
        margin-bottom: 8px;
      }
    }
  }
`

export const oDiferencial = css`
  padding: 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #312F2D;
  overflow: hidden;
  .wrapper{
    padding: 0;
  }
  h2 {
    /* position: absolute; */
    color: #EFEFEF;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    text-transform: uppercase;
    margin-bottom: 16px;
    span {
      color: #C2F57E;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #EFEFEF;
    margin-bottom: 40px;
  }
  .bt-loja-mobile{
    display: none;
  }
  .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 140px;
  }

  @media (min-width: 320px) and (max-width: 767px) {

    .bt-loja-mobile{
      width: 100%;
    display: block;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }

    .content {
      .bt-loja{
      display: none;
    }
    }
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      /* width: 100%; */
      margin-left: 0px;
      /* margin-bottom: 32px; */
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 54px 0 0 30px;
  }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }
`

export const oDiferencialParaOsPais = css`
  padding: 120px 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #EFEFEF;
  h2 {
    position: absolute;
    color: #33535E;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-top: -160px;
    width: 100%;
    max-width: 540px;
  }
  h3 {
    font-family: 'Open Sans', sans-serif;
    color: #312F2D;
    font-size: 16px;
    font-style: italic;
    font-weight: 555;
    line-height: 150%; /* 24px */
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #312F2D;
    margin-bottom: 0px;
  }
  img {
    /* margin-top: 155px; */
    /* margin-left: 230px; */
  }
  a {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.8px;
    text-transform: uppercase;
    border: 2px solid #EFEFEF;
    width: 216px;
    height: 52px;
    padding: 14px 24px 14px 32px;
    display: flex;
    justify-content: center;
    color: #EFEFEF;
    transition: transform 2000ms ease,opacity 2000ms ease,color 500ms ease,background 500ms ease,-webkit-transform 2000ms ease;
    :hover {
      background: #C2F57E;
      border: #C2F57E;
      color: #312F2D;
      text-decoration: none;
    }
    :active {
      border: 2px solid #C2F57E;
      color: #C2F57E;
      background: transparent;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
      width: 100%;
      max-width: 343px;
      margin-top: 0px;
    }
    h3 {
      margin-top: 48px;
      margin-bottom: 32px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }
`

export const atividadesAdicionais = css`
  padding: 86px 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #fff;
  h2 {
    /* position: absolute; */
    color: #33535E;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    text-transform: uppercase;
    margin-bottom: 32px;
    width: 100%;
    max-width: 540px;
  }
  h3 {
    font-family: 'obviously-narrow';
    color: #33535E;
    font-size: 16px;
    font-style: italic;
    font-weight: 555;
    line-height: 150%;
    text-transform: uppercase;
    margin-bottom: 0px;
    width: 100%;
    max-width: 380px;
  }
  p {
    font-family: 'obviously-narrow';
    font-size: 14px;
    font-style: normal;
    font-weight: 460;
    line-height: 150%;
    color: #33535E;
    margin-bottom: 0px;
    width: 100%;
    max-width: 445px;
  }
  img {
    /* margin-top: 155px; */
    /* margin-left: 230px; */
  }

  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
      width: 100%;
      max-width: 343px;
      margin-top: 0px;
    }
    h3 {
      margin-top: 48px;
      margin-bottom: 32px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }
`

export const diferenciaisContent = css`
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #33535E;

  .white-text {
    h3 {
      color: #EFEFEF;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
      margin-bottom: 56px;
    }
  p {
      font-family: 'Open Sans', sans-serif;
      color: #EFEFEF;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 32px;
      max-width: 445px;
    }
  }

  h3 {
    color: #33535E;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 56px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    color: #33535E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 32px;
    max-width: 445px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-bottom: 30px;
    img {
      width: 100%;
      margin-bottom: 32px;
    }
    h3 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    a {
      margin: auto;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-bottom: 30px;
    img {
      width: 100%;
      margin-bottom: 32px;
    }
    h3 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    a {
      margin: auto;
      margin-bottom: 88px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-bottom: 30px;
    img {
      width: 100%;
      margin-bottom: 32px;
    }
    h3 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    a {
      margin: auto;
      margin-bottom: 88px;
    }
  }
`

export const mb88 = css`
  #img_colab {
    width: 100%;
    max-width: 540px !important;
  }
`

export const contatosEVisitas = css`
  background: #33535E;
  padding: 0px;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  scroll-margin-top: 117px;
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: #EFEFEF;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #EFEFEF;
     input[type="datetime-local"]::-webkit-calendar-picker-indicator{
      filter: invert(1);
    }
  }
  form {
    p {
      font-family:"obviously-narrow";
    font-variation-settings: "wght" 700;
    }
    input {
      width: 100%;
      height: 52px;
      background: transparent;
      border-bottom: 2px solid #EFEFEF;
      border-top: none;
      border-left: none;
      border-right: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #EFEFEF;
      padding-left: 0px;
      padding-right: 24px;
      outline: none;
      &::placeholder {
        color: #EFEFEF;
      }
    }
    /* .dateTime{
      input{
        width: 50%;
        :nth-of-type(2){  
          padding-left: 24px;
        }
      }
    } */
      .date, .time{
        width: 50%;
      }
      .date{
        padding-right: 12px;
      }
      .time{
        padding-left: 12px;
      }
    select {
    font-family:"obviously-narrow";
    color:#312F2D;
    font-weight: 700;
    border: none;
    width: 100%;
    background: transparent;
    padding-left: 0;
    padding-right: 24px;
    font-size: 18px;
    line-height: 120%;
    color: #312F2D !important;
    border-bottom: 2px solid #312F2D;

    :focus{
      outline: none;
    }
    }
  }

  .contact-text{
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .map-text{
        margin-top: 40px;

        a{
          color: #EFEFEF;
        }
    }

    h2{
      color: #efefef;
      font-size: 56px;
      font-weight: 700;
      line-height: 100%;
      margin-bottom: 24px;

      span{
        color:#312F2D;
      }
    }

    h4{
    font-family:"obviously-narrow";
      color: #C2F57E;
      font-size: 20px;
      font-style: italic;
      font-weight: 600;
      text-transform: uppercase;
    }
    
   }

   .contact-form{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;

    @media (min-width: 320px) and (max-width: 767px) {
      padding: 0px;
    }
    
    form{
      width: 100%;
      max-width: 445px;

        label{
          color: #312F2D;
          font-weight:700;
          text-transform: uppercase;
        }
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 16px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
          border: 2px solid #312F2D;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #C2F57E;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 6px;
          top: 1px;
          width: 9px;
          height: 16px;
          border: solid #33535E;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

      input{
        border-bottom: 2px solid #312F2D;
        height: 32px;
        color:#33535E;
        &::placeholder{
          color: #312F2D;
        }
      }
      p{
        margin-bottom: 16px;
        input::placeholder{
          color: #312F2D;
          text-transform: uppercase;
          /* border-bottom: 2px solid #312F2D; */
        }
      }
    }
   }

  @media (min-width: 320px) and (max-width: 767px) {
    .MB-120 {
      margin-bottom: 120px;
      padding: 0 15px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .MB-120 {
      margin-bottom: 120px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    .MB-120 {
      margin-bottom: 120px;
    }
  }
`

export const btnEnviar = css`
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.8px;
  text-transform: uppercase;
  background: #3B43FF;
  width: auto;
  height: 52px;
  padding: 14px 32px 12px 32px;
  display: flex;
  justify-content: center;
  color: #EFEFEF;
  transition: transform 2000ms ease,opacity 2000ms ease,color 500ms ease,background 500ms ease,-webkit-transform 2000ms ease;
  margin-top: 40px;
  border:none;
  :hover {
    background: #33535E;
    color: #ffffff;
    text-decoration: none;
  }
  :active {
    color: #33535E;
    background: transparent;
  }
`

export const DDesktop = css`
width: 75%;
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: flex;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: flex;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`