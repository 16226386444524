import React from "react";
import Footer from "@/components/footer-interna";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import PaisTop from '@/components/pais-top'
import Materiais from '@/components/ListaDeMateriais'

const EssencialParaOPais = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Bert International Schools - Kids">
          <HeaderOne />
          <PaisTop />
          <Materiais />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default EssencialParaOPais;
