/** @jsx jsx */
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import MaskInput from 'react-awesome-mask-input';

import { 
  contatosEVisitas,
  btnEnviar
}  from '../assets/styles/LojaVirtual.styles'

const Contatos = () => {

  const [showAgendarVisita, setShowAgendarVisita] = useState(false)

  const handleCheckboxChange = (e) => {
    setShowAgendarVisita(e.target.checked)
    console.log('revelar')
  }

  return (
    <Fragment>
      <section css={contatosEVisitas} id="contatos">
        <Container fluid>
          <Row>
            <Col lg={6} md={6} sm={12} className="p-0">
              <div className="MB-120 contact-text">
                <div>
                  <h2>O MUNDO<br/> PRECISA DOS<br/> BERTS!<br/> <span>FAÇA PARTE</span></h2>

                  <p>Preencha o formulário e solicite o contato com nossa central de relacionamento</p>
                </div>
              </div>
            </Col><br/>
            <Col lg={6} md={6} sm={12} className="contact-form">
             
                {/* <h3>CONTATO</h3>
                <p>Fale com nosso time de relacionamento se quiser mais informações.</p> */}
                <form
                  name='contatos'
                  method='POST'
                  action='/sucesso'
                  data-netlify='true'
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='contatos'
                  />
                  <p>
                    <input 
                      type='text' 
                      name='name' 
                      placeholder='Nome' 
                      required 
                    />
                  </p>
                  <p>
                    <MaskInput
                      type="text"
                      mask={['(99) 9999-9999', '(99) 9 9999-9999']}
                      placeholder="Telefone"
                      name='telefone'
                    />
                  </p>
                  <p>
                    <input
                      type='mail'
                      name='email'
                      placeholder='E-mail'
                      required
                    />
                  </p>
                  <p>
                    <input 
                      type='text' 
                      name='mensagem' 
                      placeholder='Mensagem' 
                      required 
                    />
                  </p>
                  <p>
                    <label class="container">
                      <input type="checkbox" onChange={handleCheckboxChange} /> Desejo agendar uma visita
                    <span class="checkmark"></span>
                  </label>
                  </p>
                {showAgendarVisita && (
                  <div className="agendar-visita">
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p className="date">
                      <input
                        type='date'
                        name='data-visita'
                        placeholder='Escolha uma hora'
                        required
                      />
                      </p>
                      <p className="time">
                      <input
                        type='time'
                        name='hora-visita'
                        placeholder='Escolha uma hora'
                        required
                      />
                      </p>
                    </div>
                    <p>
                      <select 
                        name='unidade' 
                        required 
                      >
                        <option value=''>Escolha a unidade</option>
                        <option value='bert-1'>Bert Kids</option>
                        <option value='bert-2'>Bert</option>
                      </select>
                    </p>
                  </div>
                )}
   
                  <button css={btnEnviar} type='submit'>
                    Quero ser bert
                  </button>
                </form>
              
            </Col>
            <Col lg={1} md={1} sm={12} />
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default Contatos;
