/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { topStyles, DDesktop, DMobile } from '../assets/styles/BertKidsTop.styles.js';
import { Col, Container, Row } from 'react-bootstrap';
import paisTop from '../assets/images/lp/pais-top.jpg'
import paisMobile from '../assets/images/lp/pais-mobile.jpg'

const BertKidsTop = () => {
  return (
    <section>
      <div css={topStyles}>
        <div className="text-right">
          <img style={{ marginLeft: 'auto' }} css={DDesktop} src={paisTop} alt='/' />
          <img css={DMobile} src={paisMobile} alt='/' />
        </div>
        <Container>
          <Row className="row" style={{ alignItems: 'center' }}>
            <Col className='col-title'>
              <h2>Essencial <br /> para os pais</h2>
              <h3>Fortalecendo a parceria entre escola e pais: descubra nossos serviços e otimize a participação na educação dos seus filhos!</h3>
            </Col>
            <Col className='col-text' style={{marginTop: '32px'}} >
              <p>
              Toda atenção, tempo e investimento necessários à formação dos alunos servem para melhorar também a experiência e o relacionamento com os pais. Pensando nisso, disponibilizamos em nosso site uma variedade de serviços essenciais que antes exigiam a presença física na escola. Acreditamos que facilitar o acesso a esses serviços fortalece a parceria entre a escola e os pais, garantindo uma educação de qualidade e uma comunicação ágil e eficiente. Invista seu tempo onde realmente importa: na formação e no sucesso dos seus filhos. Estamos aqui para apoiá-los nessa jornada.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default BertKidsTop;
