/** @jsx jsx */
import { css } from '@emotion/react'

export const containerStyles = css`
  display: flex;
  /* background: #F9F9F9; */
  height: 500px;
  align-items: center;
  img {
    max-width: 285px;
    margin-right: 18px;
  }
  @media (max-width: 776px) {
    height: auto;
    margin-bottom: 60px;
}
.swiper-container .swiper-wrapper {
  transition-timing-function: linear;
}
  .swiper-slide {
    width: auto !important;
  }
  .swiper-slide-active{
    width: auto !important;
  }
@media (max-width: 850px) {
  /* max-width: 390px; */
}

`

export const imageContainer = css`
  height: 420px;
  display: flex;
`