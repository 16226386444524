/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import Girls from "@/images/lp/girls.jpg"
import GirlsMobile from "@/images/lp/girls-mobile.png"

import { 
  excelenciaInternacional,
  acaoGlobal,
}  from '../assets/styles/LojaVirtual.styles'

const ExcelenciaInternacional = () => {
  return (
    <Fragment>
      <section css={excelenciaInternacional}>
        <img className="desktop" src={Girls} />
        <img className="mobile" src={GirlsMobile} />
      </section>
    </Fragment>
    
  );
};

export default ExcelenciaInternacional;
