import { css } from "@emotion/react";

export const listaStyles = css`
  padding: 86px 0;
  font-family: "obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #c2f57e;

  .text-right {
    margin-bottom: 32px;
  }

  .col-title {
    span {
      color: #9312ff;
    }
  }

  .col-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  h2 {
    color: #33535e;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 32px;
    width: 100%;
    max-width: 540px;
  }
  h3 {
    font-family: "obviously-narrow";
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    max-width: 380px;
    color: #312f2d;
    text-transform: uppercase;
  }
  p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 460;
    line-height: 150%;
    color: #312f2d;
    margin-bottom: 0px;
    width: 100%;
    max-width: 445px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
      width: 100%;
      max-width: 343px;
      margin-top: 0px;
    }
    h3 {
      margin-top: 48px;
      margin-bottom: 32px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
    .row {
      flex-direction: column;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }
`;

export const sectionContainer = css`
  background-color: #d4ff7f;
  padding: 100px 0;
`;
export const menuContainer = css`
  /* height: 100vh; */
  padding: 0 20px;
`;

export const menuTitle = css`
  font-family: "obviously-narrow";
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #312f2d !important;
`;
export const menuItem = css`
  font-family: "obviously-narrow";
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
  color: #000;
  cursor: pointer;
  padding-bottom: 16px;
  border-bottom: 2px solid #000;
  position: relative;

  &:hover {
    color: #6633ff;
    .arrow {
      border: solid #6633ff;
      border-width: 0 2px 2px 0;
    }
  }

  &.active {
    color: #6633ff;
    .arrow {
      border: solid #6633ff;
      border-width: 0 2px 2px 0;
    }
  }

  span {
    margin-left: 10px;
  }

  img {
    width: 45px;
    margin-right: 12px;
  }

  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    position: absolute;
    right: 0;
    top: 40%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
export const contentContainer = css`
  background-color: #294d63;
  color: #fff;
  border-radius: 15px;
  padding: 33px 47px !important;
  min-height: 328px;

  h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    color: #c2f57e;
    font-style: italic;
  }

  p {
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-style: italic;
  }
`;
export const contentTitle = css`
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-style: italic;
  margin-bottom: 40px;
  text-align: center;
  color: #efefef;
  width: 100%;
`;

export const contentModalTitle = css`
  font-family: "obviously-narrow";
  font-size: 18px;
  font-style: italic;
  margin: 40px 10px;
  text-align: center;
  color: #33535e;
  width: 100%;
`;

export const horariosModal = css`
  color: #33533e;
  font-style: italic;
  text-align: left;
  margin-bottom: 40px;
  h3{
    color: #33533e;
    font-size: 20px;
  }
  p{
    margin: 0;
  }
`;


export const listItem = css`
  display: inline-block;
  width: 25%;
  text-align: center;
  cursor: pointer;
  padding: 10px;

  a {
    color: #efefef;
  }

  p {
    padding: 8px 0;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const modal = css`
  .modal-content {
    background-color: transparent;

    button {
      background-color: #fff;
      border: none;
      border-radius: 50%;
      color: #000;
      /* font-size: 24px; */
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
      border: 1px solid #312f2d;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        width: 50%;

        a {
          color: #312f2d;
        }
      }
    }
  }
`;

export const modalContent = css`
  background-color: #efefef;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
`;
export const modalTitle = css`
  font-family: "obviously-narrow";
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  margin: 40px 10px;
  color: #33535e;
`;

export const DDesktop = css`
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: flex;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: flex;
  }
`;

export const DMobile = css`
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`;
