/** @jsx jsx */
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import { SliderTwoData } from "@/data";
import { jsx } from '@emotion/react'
import banner from '@/images/lp/banner.jpg'
import bannerMobile from '@/images/lp/banner-mobile.png'
import ButtonLink from "@/components/button"

import {mainSlider}  from '../assets/styles/SliderOne.styles'
import { mainSliderTwo, sliderContent }  from '../assets/styles/SliderTwo.styles'

const LinkVideo =
  "https://player.vimeo.com/video/851761446?h=4c4c5bed3e&autoplay=1&loop=1&title=0&byline=0&portrait=0";

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderTwo = () => {

  const hasMultipleSlides = SliderTwoData.length > 1;

  // const mainSlideOptions = {
  //   loop: false,
  //   navigation: {
  //     nextEl: "#main-slider-next",
  //     prevEl: "#main-slider-prev",
  //   },
  //   allowSlideNext: false,
  //   allowSlidePrev: false
  // };
  const mainSlideOptions = {
    loop: hasMultipleSlides, // Permitir loop apenas se houver múltiplos slides
    navigation: hasMultipleSlides
      ? {
          nextEl: "#main-slider-next",
          prevEl: "#main-slider-prev",
        }
      : false, // Desativar navegação se houver apenas um slide
    allowSlideNext: hasMultipleSlides,
    allowSlidePrev: hasMultipleSlides,
  };
  return (
    <section css={[mainSlider, mainSliderTwo]}>
      <Swiper {...mainSlideOptions}>
        {SliderTwoData.map(({ subTitle, title }, index) => (
          <SwiperSlide>
            <div className="image-layer">
              {/* <iframe
                width="1920"
                height="1080"
                src={LinkVideo}
                frameborder="0" 
                autoplay
                fullscreen
                picture-in-picture
                allowfullscreen
              /> */}
            </div>
            <Container>
              <Row css={sliderContent}>
                <Col lg={5} className="text-left">
                  <h3>{title}</h3>
                  <p>{subTitle}</p>
                  <ButtonLink color="secondary" text="seja bert" href="/#contatos" />
                </Col>
                <Col lg={7} className="text-right">
                  <img className="desktop" src={banner} />
                  <img className="mobile" src={bannerMobile} />
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default SliderTwo;
