/** @jsx jsx */
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import MapImg from "@/images/lp/map.png"
import MarkerIcon from "@/images/lp/icons/marker.svg"
import InstagramIcon from "@/images/lp/icons/instagram.svg"
import WhatsappIcon from "@/images/lp/icons/whatsapp.svg"

import {
  contatosEVisitas
} from '../assets/styles/MapKids.styles'

const MapKids = () => {
  return (
    <Fragment>
      <section css={contatosEVisitas} id="contatos">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} className="p-0">
              <div className="MB-120 contact-text">
                <div>
                  <h2>CENTRAL DE<br /> RELACIONAMENTO<br /> BERT INTERNATIONAL<br /> SCHOOL</h2>
                  <h4>Do Infantil ao Fundamental I (1º a 5º ano)</h4>
                </div>
                <div className="map-text">
                  <p><img src={MarkerIcon} /> R. Fulgêncio Pereira, 311 - Vila Yolanda, Foz do Iguaçu - PR, 85853-530</p>
                  <p><img src={InstagramIcon} /> Siga: <b>instagram.com/bertschools.kids</b></p>
                  <p><img src={WhatsappIcon} /> Fale Conosco: <b><a href='https://wa.me/5545988237999' target="_blank">(45) 98823-7999</a></b></p>
                </div>
              </div>
            </Col><br />
            <Col lg={6} md={6} sm={12} className="contact-form">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3599.467704179153!2d-54.573033300000006!3d-25.556102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f691a4682dc2d3%3A0x9d244f61711423ec!2sR.%20Fulg%C3%AAncio%20Pereira%2C%20311%20-%20Vila%20Yolanda%2C%20Foz%20do%20Igua%C3%A7u%20-%20PR%2C%2085853-530!5e0!3m2!1spt-BR!2sbr!4v1734711033433!5m2!1spt-BR!2sbr" width="475"
                height="374"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>
            </Col>
            <Col lg={1} md={1} sm={12} />
          </Row>
        </Container>
      </section>
    </Fragment>

  );
};

export default MapKids;
