/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import ImageSwiper from './Image-swiper';
import { containerStyles } from '../assets/styles/BertKidsSwiper.styles';

const BertKidsSwiper = () => {
  return (
    <section css={containerStyles}>
      <ImageSwiper />
    </section>
  );
};

export default BertKidsSwiper;
