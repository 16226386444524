/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import ImageBertSwiper from './image-bert-swiper';
import { containerStyles } from '../assets/styles/BertKidsSwiper.styles';

const BertSwiper = () => {
  return (
    <section css={containerStyles}>
      <ImageBertSwiper />
    </section>
  );
};

export default BertSwiper;
