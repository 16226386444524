/** @jsx jsx */
import { jsx } from '@emotion/react'

import Product01 from '../assets/images/lp/loja/CAMISA_KIDS_MURANO.png'
import Product02 from '../assets/images/lp/loja/CAMISETA-KIDS-MASC-ESTAMPADA-R6600.png'
import Product03 from '../assets/images/lp/loja/CONJ-BERT-VERDE-FEM.-CAMISETA-BABY.png'
import Product04 from '../assets/images/lp/loja/MEIA-BERT_LOGO.png'
import Product05 from '../assets/images/lp/loja/SHORT_MURANO.png'
import Product06 from '../assets/images/lp/loja/TOUCA-BERT-12-R5500.png'
// import shirt from '../assets/images/lp/store/shirt.png'
// import cap from '../assets/images/lp/store/cap.png'

import {
  containerStyles,
} from '../assets/styles/storeCarousel.styles'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode, Navigation } from 'swiper'
const CarouselStore = () => {
  return (
    <div css={containerStyles}>
      <Swiper
        className='swiper-container'
        modules={[Autoplay, FreeMode, Navigation]}
        loop={true}
        freeMode={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        speed={8000}
        slidesPerView={'auto'}
      >
        <SwiperSlide>
          <img src={Product01} alt="Casaco com capuz da bertoni pro" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Product02} alt="Camisa regata estilo de basquete, da bertoni pro" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Product03} alt="boné tipo caminhoneiro da bertoni pro" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Product04} alt="boné tipo caminhoneiro da bertoni pro" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Product05} alt="boné tipo caminhoneiro da bertoni pro" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Product06} alt="boné tipo caminhoneiro da bertoni pro" />
        </SwiperSlide>

      </Swiper>
    </div>
  )
}

export default CarouselStore