/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { nossasTurmasStyles } from '../assets/styles/NossasTurmasBert.styles';
import { Col, Container, Row } from 'react-bootstrap';
import studentsPlayingFlaut from '../assets/images/lp/bert-turma.jpg'
const NossasTurmasBert = () => {
  return (
    <section css={nossasTurmasStyles}>
      <Container className='content' fluid>
        <h1>NOSSAS TURMAS:</h1>
        <Row>
        <Col>
          <h2>Ensino Fundamental Anos Finais</h2>
          <h3>Do 6º a 9º ano</h3>
          <Row className='row-list'>
            <Col>
            <ul>
              <li>Aulas em inglês de Saúde & Ciências e História</li>
              <li>Programa socioemocional</li>
              <li>Grupos de estudo</li>
              <li>Ensino de qualidade em Linguagem, Matemática, Ciências</li>
              <li>Avaliação formativa</li>
            </ul>
            </Col>
            <Col>
            <ul>
              <li>Sistema POLIEDRO</li>
              <li>Pensamento Matemático</li>
              <li>Metodologias ativas em sinergia com aulas convencionais </li>
              <li>Orientação pedagógica personalizada </li>
            </ul>
            </Col>
          </Row>
        </Col>
        <Col className='col-img'>
          <img src={studentsPlayingFlaut} />
        </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NossasTurmasBert;
