/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { fundAnosIniciaisStyles } from '../assets/styles/FundAnosIniciais.styles';
import { Col, Container, Row } from 'react-bootstrap';
import studentsLego from '../assets/images/lp/students-lego.png'

const FundamentalAnosIniciais = () => {
  return (
    <section css={fundAnosIniciaisStyles}>
      <Container className='content' fluid>
        <Row className='row-reverse'>
          <Col>
            <h2>Fundamental Anos Iniciais</h2>
            <h3>Do 1° ao 5 ano</h3>
            <Row className='row-list'>
              <Col>
                <ul>
                  <li>Bilinguismo</li>
                  <li>STEAM</li>
                  <li>Pensamento matemático</li>
                  <li>Teatro</li>
                  <li>Integral e semi-integral</li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li>Programa Socioemocional</li>
                  <li>Cooking Class</li>
                  <li>Jardinagem</li>
                  <li>YLE Preparação Cambridge Young Learners</li>
                </ul>
              </Col>
            </Row>
          </Col>
          <div className='col-img'>
            <img src={studentsLego} alt="Estudentes construindo uma torre com lego" />
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default FundamentalAnosIniciais;
