/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { topStyles, DDesktop, DMobile } from '../assets/styles/BertKidsTop.styles.js';
import { Col, Container, Row } from 'react-bootstrap';
import betKids from '../assets/images/lp/bert-kids.png'
import betKidsMob from '../assets/images/lp/bert-kids-mob.png'

const BertKidsTop = () => {
  return (
    <section>
      <div css={topStyles}>
        <div className="text-right">
          <img style={{ marginLeft: 'auto' }} css={DDesktop} src={betKids} alt='/' />
          <img css={DMobile} src={betKidsMob} alt='/' />
        </div>
        <Container>
          <Row style={{ alignItems: 'center' }}>
            <Col className='col-title'>
              <h2>Bert <br /> International Schools - Kids</h2>
              <h3>Um ensino de qualidade na base prepara melhor as crianças para os anos seguintes. <span>Comece o quanto antes</span></h3>
            </Col>
            <Col className='col-text' >
              <p>
                É a preparação nos anos iniciais que impulsiona o sucesso. Priorizamos o desenvolvimento da inteligência cognitiva, emocional e afetiva das crianças por meio do aprendizado ativo e da educação bilíngue.
              </p>
              <p>
                Aqui, nossos alunos aprendem o idioma em uma vivência diária, a partir do momento que chegam à escola, através de atividades divertidas, interessantes e que fazem sentido no seu contexto infantil.
              </p>
              <p>
                Do estímulo ao desenvolvimento da comunicação, do interesse em aprender ao conforto para usar o idioma. Preparamos nossos alunos para que possam atingir seu pleno potencial, no presente e no futuro!
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default BertKidsTop;
