/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/react";
import { nossasTurmasStyles } from '../assets/styles/NossasTurmas.styles';
import { Col, Container, Row } from 'react-bootstrap';
import studentsPlayingFlaut from '../assets/images/lp/students-playing-flaut.png'
const NossasTurmas = () => {
  return (
    <section css={nossasTurmasStyles}>
      <Container className='content' fluid>
        <h1>NOSSAS TURMAS:</h1>
        <Row>
        <Col>
          <h2>Educação Infantil</h2>
          <h3>Do Maternal ao Infantil V</h3>
          <Row className='row-list'>
            <Col>
            <ul>
              <li>Bilinguismo</li>
              <li>Pensamento Matemático</li>
              <li>Musicalização</li>
              <li>Teatro</li>
              <li>Educação Socioemocional</li>
            </ul>
            </Col>
            <Col>
            <ul>
              <li>Cooking Class</li>
              <li>Jardinagem</li>
              <li>Xadrez</li>
              <li>Currículo orientado ao exame do Cambridge Young Learner</li>
            </ul>
            </Col>
          </Row>
        </Col>
        <Col className='col-img'>
          <img src={studentsPlayingFlaut} alt="Estudentes tocando flauta" />
        </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NossasTurmas;
