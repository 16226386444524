/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import ImgCurriculo from "@/images/lp/img1.svg"
import ButtonLink from "@/components/button"

import {
  lideresHeader,
  bertBert,
  DDesktop,
  DMobile,
} from '../assets/styles/CurriculoHome.styles'

const LideresGlobaisHome = () => {
  return (
    <Fragment>
      <section css={lideresHeader}>
        <Container>
          <Row>
            {/* <Col lg={1} md={6} sm={12} /> */}
            <Col lg={6} md={6} sm={12} data-aos-delay={"300"} data-aos="fade-right">
              <h2>Currículo além<br /> das <span>fronteiras</span></h2>
              <h4>Escola internacional e bilíngue em Foz do Iguaçu</h4>

              <img className="imgCurriculo" src={ImgCurriculo} />
            </Col>
            <Col lg={6} md={6} sm={12} data-aos-delay={"300"} data-aos="fade-left">
              <p><b>Somos um colégio internacional, integral e bilíngue, com currículo amplo que prepara o aluno para se destacar na vida e nas melhores universidades do Brasil e do mundo.</b> Da Educação Infantil ao Ensino Médio, nossos alunos alcançam desenvolvimento socioemocional, fluência em idiomas, melhores oportunidades e aprovações universitárias!</p>

              <p>Acreditamos nos benefícios cognitivos, linguísticos e acadêmicos do contato com o bilinguismo desde a infância. Por isso, ao concluir a
                jornada Bert, garantimos que nossos estudantes estejam aptos para o sucesso acadêmico!</p>

              <div style={{ padding: ' 30px 0 73px' }}>
                <ButtonLink
                  color="default"
                  text="saiba mais"
                  href="/#unidades"
                />
              </div>

              {/* <div className="btn-agende">
<Link to='/'>
                  SAIBA MAIS
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='none'
                  >
                    <g clip-path='url(#clip0_984_545)'>
                      <path
                        d='M6.62904 1.54102L11.8371 6.74903C11.984 6.89598 11.984 7.13259 11.8371 7.27705L6.62904 12.4851'
                        stroke='#ffffff'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='square'
                      />
                      <path
                        d='M11.375 7L1.75 7'
                        stroke='#ffffff'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='square'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_984_545'>
                        <rect
                          width='14'
                          height='14'
                          fill='white'
                          transform='translate(14) rotate(90)'
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
</div> */}


              <h2>Metodologia <span>steam</span></h2>
              <h4>Educação integrada e aplicada</h4>

              <p>Nosso enfoque na metodologia STEAM (Ciência, Tecnologia, Engenharia, Artes e Matemática) é prático e eficiente. Garantimos que
                estudantes adquiram conhecimentos integrados e aplicados nessas cinco áreas essenciais. Ao investir em uma educação que une
                teoria e prática, preparamos nossos alunos para serem inovadores, pensadores críticos e líderes em suas futuras carreiras.</p>

              <p><b>O caminho para o futuro começa no BERT, com formação acadêmica de excelência!</b></p>
              <div style={{ padding: ' 30px 0 0' }}>
                <ButtonLink color="default" text="conheça os projetos" href="/#projetos" />
              </div>

            </Col>
          </Row>
        </Container>
      </section>
      {/* <section css={bertBert}>
        <Container>
          <Row>
            <Col css={DDesktop} lg={7} md={6} sm={12}>
              <div style={{ display: 'flex' }}>
                <h1>BERT</h1>
                <img src={ImgBert} />
              </div>
            </Col>
            <Col css={DMobile} sm={12}>
              <div className="bertMobile">
                <h1>BERT</h1>
                <img src={ImgBert} />
                <h2>BER</h2>
              </div>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <h1 css={DDesktop} style={{ marginTop: 60 }}>BERT</h1>
              <p>Junte-se a nós! Deixe sua marca no mundo e carimbe o passaporte de cidadania global.</p>
              <div className="btn-agende">
                <Link to='/'>
                  AGENDE UMA VISITA
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='none'
                  >
                    <g clip-path='url(#clip0_984_545)'>
                      <path
                        d='M6.62904 1.54102L11.8371 6.74903C11.984 6.89598 11.984 7.13259 11.8371 7.27705L6.62904 12.4851'
                        stroke='#312F2D'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='square'
                      />
                      <path
                        d='M11.375 7L1.75 7'
                        stroke='#312F2D'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='square'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_984_545'>
                        <rect
                          width='14'
                          height='14'
                          fill='white'
                          transform='translate(14) rotate(90)'
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </Fragment>

  );
};

export default LideresGlobaisHome;
