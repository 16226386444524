import { css } from '@emotion/react'

export const topStyles = css`
  padding: 86px 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #fff;

  .text-right{
    margin-bottom: 32px;
  }

  .col-title {
    span {
      color: #9312FF;
    }
  }

  .col-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  h2 {
    color: #33535E;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    text-transform: uppercase;
    margin-bottom: 32px;
    width: 100%;
    max-width: 540px;
  }
  h3 {
    font-family: 'obviously-narrow';
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    max-width: 380px;
    color: #312F2D;
    text-transform: uppercase;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 460;
    line-height: 150%;
    color: #312F2D;
    margin-bottom: 0px;
    width: 100%;
    max-width: 445px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
      width: 100%;
      max-width: 343px;
      margin-top: 0px;
    }
    h3 {
      margin-top: 48px;
      margin-bottom: 32px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
    .row{
      flex-direction: column;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    p {
      margin-top: 0px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
    .justifyCenter {
      display: flex;
      justify-content: center;
    }
  }
`

export const DDesktop = css`
width: 75%;
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: flex;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: flex;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`