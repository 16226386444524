import { css } from '@emotion/react'

export const nossasTurmasStyles = css`
padding-top: 72px;
padding-bottom: 56px;
@media (min-width: 320px) and (max-width: 767px) {
   flex-direction: column;
   padding-bottom: 0px !important;
  }
.row-list {
  padding-top: 40px;
  @media (min-width: 320px) and (max-width: 767px) {
   flex-direction: column;
   margin-bottom: 32px !important;
  }
}
ul {
  margin: 0;
  padding-left: 20px;
  li {
    color: #312F2D;
    list-style: disc;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  li:not(:last-of-type) {
    padding-bottom: 16px;
  }
}
.col {
  padding-inline: 0;
}
.row {
  margin: 0;
  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;

    .col:first-child{
      padding-left: 22px;
      .col{
        padding-left: 0;
      }
    }
  }
}
.content {
  padding-left: 140px;
  padding-right: 0;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-left: 0px;
  }
}
h1 {
  
  font-family: 'obviously-narrow';
  font-size: 56px;
  font-weight: 700;
  line-height: 56px;
  color: #33535E;
  margin-bottom: 40px;
  @media (min-width: 320px) and (max-width: 767px) {
   margin-bottom: 64px;
   padding-left: 22px;
  }
}
h2 {
  color: #312F2D;
  font-family: obviously-narrow;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
    
}
h3 {
  color:#33535E;
  font-family: obviously-narrow;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  text-transform: uppercase;
}

.col-img{
  display:flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 767px) {
    img{
      width: 100%;
    };
  }
}
`