/** @jsx jsx */
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const accordion = css`
  font-weight: 400;
  overflow: hidden;
  border: none;
  .card__header {
    background: none;
  }
  :last-of-type{
      hr{
        display: none;
      }
      @media (min-width: 320px) and (max-width: 767px) {
        hr{
          display: block;
        }
      }
    }
  .card {
    background: inherit;
    border: none;
    &__header {
      padding: 0;
    }

    &__body {
      color: #3B43FF;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-family: 'Open Sans';
      font-weight: 400 !important;
      line-height: 150%;
      margin-top: 0px;
      margin-bottom: 16px;
      padding: 16px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
  .card-header {
    border-bottom: none;
  }
`

export const Button = styled.button`
  border: none;
  background: transparent;
  color: #212121;
  text-align: left;
  padding: 0;
  margin-bottom: ${(props) => (props.isShowing ? '14.5px' : '0px')};
  width: 100%;

  .title {
    align-items: baseline;
    display: flex;
    gap: 20px;
    margin-top: 16px;
    padding: 16px 16px 16px 0px;
    justify-content: space-between;
    @media (max-width: 500px) {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    h5 {
      margin-top: 34px;
      color: #0c79fe;
      font-size: 16px;
      font-weight: 700;
      @media (max-width: 500px) {
        font-size: 12px;
        line-height: 14.4px;
        font-weight: 600;
        margin-top: 15px;
      }
    }
    h3 {
      color: #212121;
      font-size: 24px;
      font-weight: 700;
      line-height: 130%;
      margin-bottom: 0;
      text-transform: uppercase;
      @media (max-width: 500px) {
        font-size: 22px;
        line-height: 24px;
      }
    }

    span {
      align-items: center;
      transform: ${(props) =>
        props.isShowing ? 'rotate(180deg)' : 'rotate(0deg)'};
      transition: all 0.3s ease-out;
      display: flex;
      justify-content: center;
      height: 2em;
      width: 2em;
      padding: 0.25em;
      border-radius: 50%;
      &:hover {
        background-color: #edf0f7fc;
        .icon {
          color: #0c79fe;
          transition: color 0.3s ease-out;
        }
      }
    }
  }

  .text {
    color: #fff;
    display: ${(props) => (props.isShowing ? 'none' : 'initial')};
    font-weight: 400 !important;

    p {
      color: #fff;
      line-height: 150%;
      margin: 0;
    }
  }

  @media (max-width: 900px) {
    margin-bottom: 16px;
  }
`

export const hrStyle = css`
  border-bottom: 2px solid #000000;
  margin: 0;
`
