/** @jsx jsx */
import React, { Fragment } from "react";
import AuthorCard from "@/components/author-card";
import Comments from "@/components/comments";
import blogDetailsImage from "@/images/blog/7.jpg";
import DateFormat from "@/components/date-format"
import ReactMarkdown from "react-markdown";
import { jsx } from '@emotion/react'

import { 
  singleBlog,
  blogThumb,
  blogHeadings,
  blogDate,
  blogMetas,
  blogDetails,
  blogTagItens
}  from '../assets/styles/SinglePostCard.styles'



const SinglePostCard = (props) => {
  const { post, url } = props;

  return (
    <Fragment>
      <div css={singleBlog}>
        <div css={blogHeadings}>
          <h2>{post?.attributes?.titulo}</h2>
          <p css={blogMetas}>
            <a href="#">Publicado por {post?.attributes?.autor}</a>
            <p><DateFormat createdDate={post?.attributes?.createdAt} /></p>
            {/* -<a href="#">30 Likes</a>-
            <a href="#">2 Comments</a> */}
          </p>
        </div>
        <div css={blogThumb}>
          {post?.attributes?.imagem_destaque && (
            <img src={url+post?.attributes?.imagem_destaque?.data?.attributes?.url} alt={post?.attributes?.imagem_destaque?.data?.attributes?.name} />
          )}
        </div>
        <div css={blogDetails}>
         <ReactMarkdown>{post?.attributes?.conteudo}</ReactMarkdown>
        </div>
        {/* <div css={blogTagItens}>
          <span>Tags:</span>
          <a href="#">Business</a>, <a href="#">Agency</a>,{" "}
          <a href="#">Digital</a>, <a href="#">Technology</a>
        </div>
        <AuthorCard />
        <Comments /> */}
      </div>
    </Fragment>
  );
};

export default SinglePostCard;
