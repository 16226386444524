/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import ImgBert from "@/images/lp/home/visao-missao.png"
import Crianca from "@/images/lp/crianca.jpg"
import CriancaMobile from "@/images/lp/menino-mobile.jpg"
import WorldBert from "@/images/lp/worldbert.svg"
import Accordion from "@/components/Accordion";
import Accordion2 from "@/components/Accordion2";

import { 
  principiosHeader,
  visaoMissao,
  imgTop
}  from '../assets/styles/PrincipiosOrientadores.styles'

const PrincipioOrientadores = () => {
  return (
    <Fragment>
      <section id="sobre" css={principiosHeader}>
        <Container>
          <Row>
            <Col className="imgTop" lg={12} md={12} sm={12}>
              <img className="desktop" src={Crianca} />
              <img className="mobile" src={CriancaMobile} />
            </Col>
           
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} data-aos-delay={"550"} data-aos="fade-up">
              <h2>Visão</h2>
              <p>Ser uma escola de referência global.</p>
              <p>Inspirar e preparar líderes interessados em enfrentar os principais desafios da humanidade.</p>
              <p>Ser um centro reconhecido de excelência acadêmica.</p>
              <p>Proporcionar aos alunos as melhores oportunidades de sucesso acadêmico e profissional.</p>
            </Col>
            <Col lg={6} md={6} sm={12} data-aos-delay={"600"} data-aos="fade-up">
              <h2>Missão</h2>
              <p>Preparar alunos para se tornarem protagonistas nas melhores universidades do mundo.</p>
              <p>Formar cidadãos comprometidos com a transformação positiva da sociedade.</p>
              <p>Incentivar o interesse pelo estudo permanente.</p>
              <p>Estimular a diversidade cultural, o pensamento crítico e a originalidade na resolução de problemas.</p>
            </Col>
          </Row>
          <Row className="wsection" style={{ marginTop: 75 }}>
          <Col lg={6} md={6} sm={12} data-aos-delay={"400"} data-aos="fade-right" style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
              <h2>Valores</h2>
              <div className="valores">
                <p>Para abraçar oportunidades globais, ampliar horizontes de crescimento e impactar o mundo, é preciso uma base sólida em valores e paixão pelo desenvolvimento intelectual. Com nossos valores articulamos educação de excelência e inovação para criar impacto global.</p>
              </div>
            </Col>
            <Col className="world" lg={6} md={6} sm={12} data-aos-delay={"400"} data-aos="fade-left">
                <img src={WorldBert} />
            </Col>
            </Row>
           <Row>
           <Col lg={6} md={6} sm={12} data-aos-delay={"450"} data-aos="fade-left">
              <Accordion />
            </Col>
            <Col lg={6} md={6} sm={12} data-aos-delay={"500"} data-aos="fade-left">
              <Accordion2 />
            </Col>
           </Row>
          
        </Container>
      </section>
     
    </Fragment>
    
  );
};

export default PrincipioOrientadores;
