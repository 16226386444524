import React from "react";
import Footer from "@/components/footer-interna";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import BertKidsTop from '@/components/bert-kids-top'
import BertKidsSwiper from '@/components/bert-kids-swiper';
import NossasTurmas from '@/components/NossasTurmas';
import FundamentalAnosIniciais from '@/components/FundamentalAnosIniciais';
import MapKids from '@/components/MapKids';

const BertKidsPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Bert International Schools - Kids">
          <HeaderOne />
          <BertKidsTop />
          <BertKidsSwiper />
          <NossasTurmas />
          <FundamentalAnosIniciais/>
          <MapKids />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default BertKidsPage;