import { css } from '@emotion/react'
import BgBertKids from '../images/lp/home/bg-bert-kids.png'

export const bertKidsHeader = css`
  padding: 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  position: relative;
  /* background: url(${BgBertKids}) no-repeat center center / cover; */
  .bgImg {
      width: 100%;
  }
  .bgImg.mobile {
    display: none;
  }

    .p0{
      padding: 0;
      position: relative;
      
      .content{
        position: absolute;
        top:45px;
        left: 45px;
        z-index: 3;
        @media (min-width: 320px) and (max-width: 767px) {
          left: 32px;
        }
      }

      h2{
        color: #ffffff;    
        font-family:"obviously-narrow";
        font-size: 56px;
        text-transform: uppercase;
        font-weight: 700;
        transition: all .3s;
        line-height: 56px;

        @media (min-width: 320px) and (max-width: 767px) {
          font-size: 48px;
        }
      }

      :hover{
        h2{
          color: #3B43FF;
        }
        .overlay{
          background-color: rgba(0,0,0,0);
        }
      }

    }

    .overlay{
      background-color: rgba(0,0,0,0.7);
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
      top: 0;
      left: 0;
      transition: all .3s;
    }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #312F2D;
    margin-bottom: 0px;
    margin-top: 56px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-bottom: 0px;
    /* padding-top: 80px; */
    background: #FFC501;
    p {
      margin-top: 24px;
      margin-bottom: 16px;
      text-align: center;
      color: #312F2D;
    }
    /* img {
      width: 100%;
    } */
    .bgImg {
      display: none;
    }
    .bgImg.mobile {
      display: block;
    }
    .overlay{
      background-color: rgba(0,0,0,0);
    }
    .content{
        position: absolute;
        bottom:32px;
        top: auto !important;
        height: fit-content;
      }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-top: 80px;
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 32px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      font-size: 56px;
      margin-bottom: 36px;
    }
    h3 {
      margin-bottom: 32px;
    }
    img {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 32px;
    }
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`