/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import { Link } from "gatsby";
import Arrow from "@/images/lp/arrow.svg"

import { 
  commonBtn
}  from '../assets/styles/layout.styles'

const Button = ({color, text, href}) => {
  return (
    <Fragment>
      <div css={commonBtn} className={color}>
          <Link to={href}>
            {text}{' '}
            <img src={Arrow} />
          </Link>
      </div>
    </Fragment>
    
  );
};

export default Button;
