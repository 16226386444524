/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import { Link } from "gatsby";
import BertKidsImg from "@/images/lp/kids.jpg"
import BertSchoolsImg from "@/images/lp/schools.jpg"
import BertKidsImgMobile from "@/images/lp/bertKidsMobile.png"
import BertSchoolsImgMobile from "@/images/lp/bertMobile.png"
import ButtonLink from "@/components/button"

import { 
  bertKidsHeader,
  DMobile,
}  from '../assets/styles/BertKids.styles'

const BertKids = () => {
  return (
    <Fragment>
      <section id="unidades" css={bertKidsHeader}>
        <Container fluid>
          <Row>
            <Col lg={6} md={6} sm={12} className="p0">
              <div className="content">
                <h2>
                  Bert<br/>
                  International<br/>
                  School Kids
                </h2>
                  <div style={{ padding:' 30px 0 0'}}>
                  <ButtonLink color="secondary" text="saiba mais" href="/bert-kids" />
                  </div>
              </div>
              <img className="bgImg desktop" src={BertKidsImg} alt='' />
              <img className="bgImg mobile" src={BertKidsImgMobile} alt='' />
              {/* <p>Imaginação e criatividade são valores que são cultivados desde cedo. Nosso segmento Infantil segue metodologia inovadora ao posicionar o professor como tutor de grupos com 5 alunos cada. Cada grupo desenvolve projetos que ampliam o repertório do aluno, usando linguagem, artes, música e ciência.</p> */}
              <div className="overlay"></div>
            </Col>
            <Col lg={6} md={6} sm={12} className="p0">
            <div className="content">
                <h2>
                  Bert<br/>
                  International<br/>
                  Schools
                </h2>
                  <div style={{ padding:' 30px 0 0'}}>
                  <ButtonLink color="secondary" text="saiba mais" href="/bert" />
                  </div>
              </div>
              <img className="bgImg desktop" src={BertSchoolsImg} alt='' />
              <img className="bgImg mobile" src={BertSchoolsImgMobile} alt='' />
              {/* <p>Imaginação e criatividade são valores que são cultivados desde cedo. Nosso segmento Infantil segue metodologia inovadora ao posicionar o professor como tutor de grupos com 5 alunos cada. Cada grupo desenvolve projetos que ampliam o repertório do aluno, usando linguagem, artes, música e ciência.</p> */}
              <div className="overlay"></div>
            </Col>
          </Row>
        </Container>
        {/* <div className="text-center">
          <img css={DMobile} src={BgBertKids} alt='' />
        </div> */}
      </section>
    </Fragment>
    
  );
};

export default BertKids;
