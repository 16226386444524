/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import IcoArrow from "@/images/lp/icons/arrow-b.svg"
import IcoCiaTeatro from "@/images/lp/icons/cia-teatro.svg"
import IcoFabLab from "@/images/lp/icons/fab-lab.svg"
import IcoStrike from "@/images/lp/icons/strike.svg"

import { 
  diferenciais,
}  from '../assets/styles/DiferenciaisHome.styles'
import { Link } from "gatsby"

const DiferenciaisHome = () => {
  return (
    <Fragment>
      <section css={diferenciais} id="projetos">
        <Container>
          <Row>
          <Col lg={12} md={12} sm={12}>
            <h2>Projetos</h2>
          </Col>
          </Row>
          <Row>
            <Col lg={8} md={8} sm={12}>
              <Row className="mb-56">

                <div className="project-title">
                  <div className="textCenter">
                    <img src={IcoCiaTeatro} alt='' />
                  </div>

                  <div className="title">
                    <Link to='/atividades-adicionais'>CIA DE TEATRO BERTONI <img src={IcoArrow} alt='' /></Link>
                  </div>
                </div>

              <Col lg={12} md={12} sm={12}>
              <p>Encenar clássicos do teatro enquanto desenvolve competências como empreendedorismo, colaboração e autoconfiança. Ensaiar, produzir e apresentar espetáculos como exercício de liderança e autogestão. Be Bert, Be Global. There is no question!</p>
              </Col>
            </Row>
            <Row className="mb-56">

            <div className="project-title">
                <div className="textCenter">
                  <img src={IcoFabLab} alt='' />
                </div>

                <div className="title">
                  <Link to='/atividades-adicionais'>Digital FabLab <img src={IcoArrow} alt='' /></Link>
                </div>
              </div>

              <Col lg={12} md={12} sm={12}>
              <p>Desenvolver projetos para enfrentar desafios da nova economia digital. No Bert, você tem um laboratório de fabricação digital inspirado no MIT - Instituto de Tecnologia de Massachusetts (USA) - espaço pensado para articular competências das áreas de tecnologia, engenharia, artes e design.</p>
              </Col>
            </Row>
            </Col>
            <Col lg={4} md={4} sm={12}>
            <Row>
            <div className="project-title steam">
              <div className="textCenter">
                <img src={IcoStrike} alt='' />
              </div>

              <div className="title">
                <Link to='/atividades-adicionais'>STEAM <img src={IcoArrow} alt='' /></Link>
              </div>

            </div>
            <Col lg={12} md={12} sm={12}>
            <p><b>DUAS VEZES CAMPEÃ DO MUNDO.</b></p> <br />
            <p>
            A Strike 6902 é a equipe de robótica formada por alunos do Bert. De Montreal a Istambul, os jovens que formam a Strike aprendem lições que vão além da tecnologia e passam pelo design, marketing e gestão, ao mesmo tempo em que promovem intenso intercâmbio com diferentes culturas nas viagens que realizam pelo mundo.</p>
            </Col>
          </Row>
            </Col>
          </Row>
         
          
        </Container>
      </section>
    </Fragment>
    
  );
};

export default DiferenciaisHome;
