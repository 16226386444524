/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useContext } from 'react'
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from 'react-bootstrap'

import { Button, accordion, hrStyle } from '../assets/styles/Accordion.styles'
import ArrowDown from '../assets/icons/arrow-down';

function ContextAwareToggle({ eventKey, callback, title, subtitle, preTitle }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Button
      type='button'
      onClick={decoratedOnClick}
      isShowing={isCurrentEventKey}
    >
      <div className='title'>
        <div>
          <h3 style={{ color: `${isCurrentEventKey ? '#3B43FF' : 'initial'}`}}>{title}</h3>
        </div>
        <span>
          <ArrowDown color={`${isCurrentEventKey ? '#3B43FF' : ''}`} className='icon' />
        </span>
      </div>
      <div className='text'>
        <p>{subtitle}</p>
      </div>
    </Button>
  )
}

function AccordionSection({ title, subtitle, children, preTitle, eventKey, callback, isOpen }) {
  return (
    <Accordion css={accordion} activeKey={isOpen ? eventKey : null}>
      <Card style={{ borderRadius: 0 }} className='card'>
        <Card.Header className='card__header'>
          <ContextAwareToggle
            eventKey={eventKey}
            preTitle={preTitle}
            title={title}
            subtitle={subtitle}
            callback={callback}
          />
        </Card.Header>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className='card__body'>{children}</Card.Body>
        </Accordion.Collapse>
        <hr css={hrStyle} />
      </Card>
    </Accordion>
  )
}

export default AccordionSection
