/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby"
import logoBert from "@/images/lp/logo-footer.svg"
import Instagram from "@/images/lp/icons/instagram.svg"
import Facebook from "@/images/lp/icons/facebook.svg"
import Youtube from "@/images/lp/icons/youtube.svg"
import Maps from "@/images/lp/icons/maps.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Navigation } from 'swiper'

import {
  footerInternaSection,
  footerContent,
  socialIcons,
  unidadesSec,
  copySection,
  DMobile,
  DDesktop,
  DMobileMenu,
  acompanheMobile,
  paisMobile,
  unidadesSecMobile
} from "../assets/styles/Footer.styles";

const Footer = () => {
  
  return (
    <footer css={footerInternaSection}>

     <Container className="containerFluid" fluid data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <Row style={{ marginBottom: 40}}>
                <Col css={[DMobile,acompanheMobile]} lg={6} sm={12} md={6}>
                    <div css={socialIcons}>
                      <h3>ACOMPANHE</h3>
                      <div className="socialMobile" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '24px'}}>
                          <div>
                           <p>Bert Kids:</p>
                          <a href='https://www.instagram.com/bertschools.kids/' target="_blank">
                             <i className='fa fa-instagram'></i>
                           </a>
                           <a href='https://web.facebook.com/bertschools.kids' target="_blank">
                             <i className='fa fa-facebook'></i>
                           </a>
                           <a href='https://wa.me/5545988276543' target="_blank">
                             <i className='fa fa-whatsapp'></i>
                           </a>
                          </div>
           
                          <div>
                           <p>Bert:</p>
                          <a href='https://www.instagram.com/bert.schools/' target="_blank">
                             <i className='fa fa-instagram'></i>
                           </a>
                           <a href='https://web.facebook.com/bert.schools' target="_blank">
                             <i className='fa fa-facebook'></i>
                           </a>
                           <a href='https://wa.me/5545988237999' target="_blank">
                             <i className='fa fa-whatsapp'></i>
                           </a>
                          </div>
                      </div>
                    </div>
                </Col>
            </Row>
        <Row css={DDesktop}>
          <Col lg={6} sm={12} md={6}>
            <a href="/">
              <img src={logoBert} alt="" style={{ marginBottom: 24}} />
            </a>
            <p style={{ width: '100%', maxWidth: 280 }}>Bert International Schools e Bert Schools são marcas registradas de Rede Bertoni. Todos os direitos reservados.</p>
          </Col>
          <Col lg={1} sm={4} md={1}>
            <div css={footerContent}>
              <h2>SOBRE NÓS</h2>
              
            </div>
          </Col>
          <Col lg={2} sm={4} md={2}>
            <div css={footerContent}>
              <h2>EXTRACURRICULARES</h2>
              <Link to='/atividades-adicionais'>Companhia de teatro</Link>
              <Link to='/atividades-adicionais'>Colaborative fablab</Link>
              <Link to='/atividades-adicionais'>STEAM</Link>
             
            </div>
          </Col>
          <Col lg={1} sm={4} md={1}>
            <div css={footerContent}>
              <h2>UNIDADES</h2>
               <Link to='/#turmas'>Bert Kids</Link>
              <Link to='/#turmas'>Bert</Link>
              {/* <Link to='/essencial-para-os-pais'>Calendário</Link>
              <Link to='/essencial-para-os-pais'>Documentos</Link>
              <Link to='/essencial-para-os-pais'>Material Escolar</Link>
              <Link to='/essencial-para-os-pais'>Horários</Link>
              <Link to='/essencial-para-os-pais'>Alimentação</Link> */}
            </div>
          </Col>
           <Col lg={1} sm={4} md={1}>
            <div css={footerContent}>
              <h2>Pais</h2>
            </div>
          </Col>
          <Col lg={1} sm={4} md={1}>
            <div css={footerContent}>
              <h2>Contatos</h2>
              {/* <Link to='/#contatos'>Visita</Link>
              <Link to='/#contatos'>Mensagem</Link> */}
            </div>
          </Col>
        </Row>
        <Row css={[DMobile, DMobileMenu]} style={{ marginBottom: 40, marginTop: 24, justifyContent: 'center' }}>
                  <Col lg={1} sm={4} md={1}>
                    <div css={footerContent}>
                      <h2>SOBRE NÓS</h2>           
                    </div>
                  </Col>
                  <Col lg={2} sm={4} md={2}>
                    <div css={footerContent}>
                      <h2>EXTRACURRICULARES</h2>
                      <Link to='/atividades-adicionais'>Companhia de teatro</Link>
                      <Link to='/atividades-adicionais'>Colaborative fablab</Link>
                      <Link to='/atividades-adicionais'>STEAM</Link>            
                    </div>
                  </Col>
                  <Col lg={1} sm={4} md={1}>
                    <div css={footerContent}>
                      <h2>UNIDADES</h2>
                       <Link to='/bert-kids'>Bert Kids</Link>
                      <Link to='/bert'>Bert</Link>
                    </div>
                  </Col>
                </Row>
                <Row css={[DMobile, paisMobile]} style={{ marginBottom: 40, marginTop: 24, justifyContent: 'center' }}>
                    <Col lg={1} sm={4} md={1}>
                    <div css={footerContent}>
                      <h2>Pais</h2>
                    </div>
                  </Col>
                     <Col lg={1} sm={4} md={1}>
                    <div css={footerContent}>
                      <h2>Contato</h2>
                    </div>
                  </Col>
                </Row>
        <Row css={copySection}>
          <Col css={DDesktop} lg={6} sm={12} md={6}>
           <div css={socialIcons}>
                         <h3>ACOMPANHE</h3>
           
                          <div>
                           <p>Bert Kids:</p>
                          <a href='https://www.instagram.com/bertschools.kids/' target="_blank">
                             <i className='fa fa-instagram'></i>
                           </a>
                           <a href='https://web.facebook.com/bertschools.kids' target="_blank">
                             <i className='fa fa-facebook'></i>
                           </a>
                           <a href='https://wa.me/5545988276543' target="_blank">
                             <i className='fa fa-whatsapp'></i>
                           </a>
                          </div>
           
                          <div>
                           <p>Bert:</p>
                          <a href='https://www.instagram.com/bert.schools/' target="_blank">
                             <i className='fa fa-instagram'></i>
                           </a>
                           <a href='https://web.facebook.com/bert.schools' target="_blank">
                             <i className='fa fa-facebook'></i>
                           </a>
                           <a href='https://wa.me/5545988237999' target="_blank">
                             <i className='fa fa-whatsapp'></i>
                           </a>
                          </div>
                       </div>
          </Col>
          <Col css={DDesktop} lg={6} sm={12} md={6 }>
            <div css={unidadesSec}>
              <h2>UNIDADES</h2>
              <a>
              <p><Link to='/bert/#contatos'><i className='fa fa-map-marker'></i> <span><b>Bert</b></span> - R. Arquiteto Décio Luís Cardoso, 469 - Centro (3ª pista da Av. JK), Foz do Iguaçu - </Link></p>
                            <p><Link to='/bert-kids/#contatos'><i className='fa fa-map-marker'></i> <span><b>Bert Kids</b></span> - R. Fulgêncio Pereira, 311 - Vila Yolanda, Foz do Iguaçu - PR</Link></p>
              </a>
            </div>
          </Col>
           <Col css={DMobile} lg={6} sm={12} md={6 }>
                      <div css={[unidadesSec,unidadesSecMobile]}>
                        <h2>UNIDADES</h2>
                        <p><Link to='/bert/#contatos'><i className='fa fa-map-marker'></i> <span><b>Bert</b></span> - R. Arquiteto Décio Luís Cardoso, 469 - Centro (3ª pista da Av. JK), Foz do Iguaçu - </Link></p>
                        <p><Link to='/bert-kids/#contatos'><i className='fa fa-map-marker'></i> <span><b>Bert Kids</b></span> - R. Fulgêncio Pereira, 311 - Vila Yolanda, Foz do Iguaçu - PR</Link></p>
                      </div>
                    </Col>
        </Row>
       <Row css={DMobile} style={{ marginTop: 56, textAlign: 'center' }}>
                 <Col lg={4} sm={12} md={4}>
                   <a href="/">
                     <img src={logoBert} alt="" style={{ marginBottom: 24}} />
                   </a>
                   <p style={{ width: '100%', marginBottom: 0 }}>Bert International Schools e Bert Schools são marcas registradas de RedeBertoni. Todos os direitos reservados.
                   </p>
                 </Col>
               </Row>

      </Container>
    </footer>
  );
};

export default Footer;
