import { css } from '@emotion/react'

export const contatosEVisitas = css`
  background: #33535E;
  padding: 0px;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  scroll-margin-top: 117px;
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: #EFEFEF;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #EFEFEF;
     input[type="datetime-local"]::-webkit-calendar-picker-indicator{
      filter: invert(1);
    }
  }
  form {
    p {
      font-family:"obviously-narrow";
    font-variation-settings: "wght" 700;
    }
    input {
      width: 100%;
      height: 52px;
      background: transparent;
      border-bottom: 2px solid #EFEFEF;
      border-top: none;
      border-left: none;
      border-right: none;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      color: #EFEFEF;
      padding-left: 0px;
      padding-right: 24px;
      outline: none;
      &::placeholder {
        color: #EFEFEF;
      }
    }
    /* .dateTime{
      input{
        width: 50%;
        :nth-of-type(2){  
          padding-left: 24px;
        }
      }
    } */
      .date, .time{
        width: 50%;
      }
      .date{
        padding-right: 12px;
      }
      .time{
        padding-left: 12px;
      }
    select {
    font-family:"obviously-narrow";
    color:#312F2D;
    font-weight: 700;
    border: none;
    width: 100%;
    background: transparent;
    padding-left: 0;
    padding-right: 24px;
    font-size: 18px;
    line-height: 120%;
    color: #312F2D !important;
    border-bottom: 2px solid #312F2D;

    :focus{
      outline: none;
    }
    }
  }

  .contact-text{
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .map-text{
        margin-top: 40px;

        a{
          color: #efefef;
        }
    }

    h2{
      color: #efefef;
      font-size: 56px;
      font-weight: 700;
      line-height: 100%;
      margin-bottom: 24px;

      span{
        color:#312F2D;
      }
    }

    h4{
    font-family:"obviously-narrow";
      color: #C2F57E;
      font-size: 20px;
      font-style: italic;
      font-weight: 600;
    }
    
   }

   .contact-form{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;

    @media (min-width: 320px) and (max-width: 767px) {
      padding: 0px;
      }
    
    form{
      width: 100%;
      max-width: 445px;

        label{
          color: #312F2D;
          font-weight:700;
          text-transform: uppercase;
        }
        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 16px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
          border: 2px solid #312F2D;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #C2F57E;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 6px;
          top: 1px;
          width: 9px;
          height: 16px;
          border: solid #33535E;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

      input{
        border-bottom: 2px solid #312F2D;
        height: 32px;
        color:#33535E;
        &::placeholder{
          color: #312F2D;
        }
      }
      p{
        margin-bottom: 16px;
        input::placeholder{
          color: #312F2D;
          text-transform: uppercase;
          /* border-bottom: 2px solid #312F2D; */
        }
      }
    }
   }

  @media (min-width: 320px) and (max-width: 767px) {
    .MB-120 {
      margin-bottom: 120px;
      padding: 0 15px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .MB-120 {
      margin-bottom: 120px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    .MB-120 {
      margin-bottom: 120px;
    }
  }
`


export const DDesktop = css`
width: 75%;
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: flex;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: flex;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`